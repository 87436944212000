var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Button Toolbar with Input"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeInput) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-button-toolbar', {
    staticClass: "demo-inline-spacing",
    attrs: {
      "aria-label": "Toolbar with button groups and input groups"
    }
  }, [_c('b-button-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    }
  }, [_vm._v(" Save ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    }
  }, [_vm._v(" Cancel ")])], 1), _c('b-input-group', {
    attrs: {
      "size": "sm",
      "append": ".00",
      "prepend": "$"
    }
  }, [_c('b-form-input', {
    staticClass: "text-right",
    attrs: {
      "value": "100"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }